
 body {background: #000;}
 @keyframes rotate-loading {
     0%  {transform: rotate(0deg);-ms-transform: rotate(0deg); -webkit-transform: rotate(0deg); -o-transform: rotate(0deg); -moz-transform: rotate(0deg);}
     100% {transform: rotate(360deg);-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); -o-transform: rotate(360deg); -moz-transform: rotate(360deg);}
 }

 @-moz-keyframes rotate-loading {
     0%  {transform: rotate(0deg);-ms-transform: rotate(0deg); -webkit-transform: rotate(0deg); -o-transform: rotate(0deg); -moz-transform: rotate(0deg);}
     100% {transform: rotate(360deg);-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); -o-transform: rotate(360deg); -moz-transform: rotate(360deg);}
 }

 @-webkit-keyframes rotate-loading {
     0%  {transform: rotate(0deg);-ms-transform: rotate(0deg); -webkit-transform: rotate(0deg); -o-transform: rotate(0deg); -moz-transform: rotate(0deg);}
     100% {transform: rotate(360deg);-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); -o-transform: rotate(360deg); -moz-transform: rotate(360deg);}
 }

 @-o-keyframes rotate-loading {
     0%  {transform: rotate(0deg);-ms-transform: rotate(0deg); -webkit-transform: rotate(0deg); -o-transform: rotate(0deg); -moz-transform: rotate(0deg);}
     100% {transform: rotate(360deg);-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); -o-transform: rotate(360deg); -moz-transform: rotate(360deg);}
 }

 @keyframes rotate-loading {
     0%  {transform: rotate(0deg);-ms-transform: rotate(0deg); -webkit-transform: rotate(0deg); -o-transform: rotate(0deg); -moz-transform: rotate(0deg);}
     100% {transform: rotate(360deg);-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); -o-transform: rotate(360deg); -moz-transform: rotate(360deg);}
 }

 @-moz-keyframes rotate-loading {
     0%  {transform: rotate(0deg);-ms-transform: rotate(0deg); -webkit-transform: rotate(0deg); -o-transform: rotate(0deg); -moz-transform: rotate(0deg);}
     100% {transform: rotate(360deg);-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); -o-transform: rotate(360deg); -moz-transform: rotate(360deg);}
 }

 @-webkit-keyframes rotate-loading {
     0%  {transform: rotate(0deg);-ms-transform: rotate(0deg); -webkit-transform: rotate(0deg); -o-transform: rotate(0deg); -moz-transform: rotate(0deg);}
     100% {transform: rotate(360deg);-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); -o-transform: rotate(360deg); -moz-transform: rotate(360deg);}
 }

 @-o-keyframes rotate-loading {
     0%  {transform: rotate(0deg);-ms-transform: rotate(0deg); -webkit-transform: rotate(0deg); -o-transform: rotate(0deg); -moz-transform: rotate(0deg);}
     100% {transform: rotate(360deg);-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); -o-transform: rotate(360deg); -moz-transform: rotate(360deg);}
 }

 @keyframes loading-text-opacity {
     0%  {opacity: 0}
     20% {opacity: 0}
     50% {opacity: 1}
     100%{opacity: 0}
 }

 @-moz-keyframes loading-text-opacity {
     0%  {opacity: 0}
     20% {opacity: 0}
     50% {opacity: 1}
     100%{opacity: 0}
 }

 @-webkit-keyframes loading-text-opacity {
     0%  {opacity: 0}
     20% {opacity: 0}
     50% {opacity: 1}
     100%{opacity: 0}
 }

 @-o-keyframes loading-text-opacity {
     0%  {opacity: 0}
     20% {opacity: 0}
     50% {opacity: 1}
     100%{opacity: 0}
 }
 .loading-container,
 .loading {
     height: 200px;
     position: relative;
     width: 200px;
     border-radius: 100%;
 }


 .loading-container { margin: 40px auto;
    top: 30vh;
    

}

 .loading {
     border: 2px solid transparent;
     border-color: transparent #fff transparent #FFF;
     -moz-animation: rotate-loading 1.5s linear 0s infinite normal;
     -moz-transform-origin: 50% 50%;
     -o-animation: rotate-loading 1.5s linear 0s infinite normal;
     -o-transform-origin: 50% 50%;
     -webkit-animation: rotate-loading 1.5s linear 0s infinite normal;
     -webkit-transform-origin: 50% 50%;
     animation: rotate-loading 1.5s linear 0s infinite normal;
     transform-origin: 50% 50%;
 }

 .loading-container:hover .loading {
     border-color: transparent #E45635 transparent #E45635;
 }
 .loading-container:hover .loading,
 .loading-container .loading {
     -webkit-transition: all 0.5s ease-in-out;
     -moz-transition: all 0.5s ease-in-out;
     -ms-transition: all 0.5s ease-in-out;
     -o-transition: all 0.5s ease-in-out;
     transition: all 0.5s ease-in-out;
 }

 #loading-text {
     -moz-animation: loading-text-opacity 2s linear 0s infinite normal;
     -o-animation: loading-text-opacity 2s linear 0s infinite normal;
     -webkit-animation: loading-text-opacity 2s linear 0s infinite normal;
     animation: loading-text-opacity 2s linear 0s infinite normal;
     color: #ffffff;
     font-family: "Helvetica Neue, "Helvetica", ""arial";
     font-size: 20px;
     font-weight: bold;
     margin-top: 80px;
     margin-left: 43px;
     opacity: 0;
     position: absolute;
     text-align: center;
     text-transform: uppercase;
     top: 0;
     width: 100px;
 }

















/*
  


  CARGADOR GIRATORIO QUE DESAPARECEN LAS ULTIMAS LETRAS  

body {
    background:#000;
  }
  #load {
    position:absolute;
    width:600px;
    height:36px;
    left:50%;
    top:40%;
    margin-left:-300px;
    overflow:visible;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
    cursor:default;
  }
  
  #load div {
    position:absolute;
    width:20px;
    height:36px;
    opacity:0;
    font-family:Helvetica, Arial, sans-serif;
    animation:move 2s linear infinite;
    -o-animation:move 2s linear infinite;
    -moz-animation:move 2s linear infinite;
    -webkit-animation:move 2s linear infinite;
    transform:rotate(180deg);
    -o-transform:rotate(180deg);
    -moz-transform:rotate(180deg);
    -webkit-transform:rotate(180deg);
    color:#35C4F0;
    font-size: 3vw;
  }
  
  #load div:nth-child(2) {
    animation-delay:0.2s;
    -o-animation-delay:0.2s;
    -moz-animation-delay:0.2s;
    -webkit-animation-delay:0.2s;
  }
  #load div:nth-child(3) {
    animation-delay:0.5s;
    -o-animation-delay:0.5s;
    -webkit-animation-delay:0.5s;
    -webkit-animation-delay:0.5s;
  }
  #load div:nth-child(4) {
    animation-delay:0.8s;
    -o-animation-delay:0.8s;
    -moz-animation-delay:0.8s;
    -webkit-animation-delay:0.8s;
  }
  #load div:nth-child(5) {
    animation-delay:1s;
    -o-animation-delay:1s;
    -moz-animation-delay:1s;
    -webkit-animation-delay:1s;
  }
  #load div:nth-child(6) {
    animation-delay:1.2s;
    -o-animation-delay:1.2s;
    -moz-animation-delay:1.2s;
    -webkit-animation-delay:1.2s;
  }
  #load div:nth-child(7) {
    animation-delay:1.5s;
    -o-animation-delay:1.5s;
    -moz-animation-delay:1.5s;
    -webkit-animation-delay:1.5s;
  }
  #load div:nth-child(8) {
    animation-delay:1.8s;
    -o-animation-delay:1.8s;
    -moz-animation-delay:1.8s;
    -webkit-animation-delay:1.8s;
  }
  @keyframes move {
    0% {
      left:0;
      opacity:0;
    }
      35% {
          left: 41%; 
          -moz-transform:rotate(0deg);
          -webkit-transform:rotate(0deg);
          -o-transform:rotate(0deg);
          transform:rotate(0deg);
          opacity:1;
      }
      65% {
          left:59%; 
          -moz-transform:rotate(0deg); 
          -webkit-transform:rotate(0deg); 
          -o-transform:rotate(0deg);
          transform:rotate(0deg); 
          opacity:1;
      }
      100% {
          left:100%; 
          -moz-transform:rotate(-180deg); 
          -webkit-transform:rotate(-180deg); 
          -o-transform:rotate(-180deg); 
          transform:rotate(-180deg);
          opacity:0;
      }
  }
  
  @-moz-keyframes move {
      0% {
          left:0; 
          opacity:0;
      }
      35% {
          left:41%; 
          -moz-transform:rotate(0deg); 
          transform:rotate(0deg);
          opacity:1;
      }
      65% {
          left:59%; 
          -moz-transform:rotate(0deg); 
          transform:rotate(0deg);
          opacity:1;
      }
      100% {
          left:100%; 
          -moz-transform:rotate(-180deg); 
          transform:rotate(-180deg);
          opacity:0;
      }
  }
  
  @-webkit-keyframes move {
      0% {
          left:0; 
          opacity:0;
      }
      35% {
          left:41%; 
          -webkit-transform:rotate(0deg); 
          transform:rotate(0deg); 
          opacity:1;
      }
      65% {
          left:59%; 
          -webkit-transform:rotate(0deg); 
          transform:rotate(0deg); 
          opacity:1;
      }
      100% {
          left:100%;
          -webkit-transform:rotate(-180deg); 
          transform:rotate(-180deg); 
          opacity:0;
      }
  }
  
  @-o-keyframes move {
      0% {
          left:0; 
          opacity:0;
      }
      35% {
          left:41%; 
          -o-transform:rotate(0deg); 
          transform:rotate(0deg); 
          opacity:1;
      }
      65% {
          left:59%; 
          -o-transform:rotate(0deg); 
          transform:rotate(0deg); 
          opacity:1;
      }
      100% {
          left:100%; 
          -o-transform:rotate(-180deg); 
          transform:rotate(-180deg); 
          opacity:0;
      }
  }
  */