.layer_incu {
  
    position: absolute;
    z-index: 4;
    width: 100%;
    left: 0;
    top: 80%;
    -webkit-transform: translate3d(0,0,0);
    -ms-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
  }

  .desc_incu{
    font-size: 6vw;
    color: white;
  font-family: LinkStart;
  
  }

  .titulo_incu{
    font-size: 14vw;
    -webkit-text-stroke: 1px white;
  font-family: LinkStart;
    color: rgb(255, 255, 255, 0);
  
}

.enlace_incu{
    font-size: 5vw !important;
}




@media(min-width: 720px){

.layer_incu {
  
    position: absolute;
    z-index: 4;
    width: 100%;
    left: 0;
    top: 33%;
    -webkit-transform: translate3d(0,0,0);
    -ms-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
  }

  .desc_incu{
    font-size: 2vw;
    color: white;
  font-family: LinkStart;
  font-size: 2vw;
  }

  .titulo_incu{
    font-size: 8vw;
    -webkit-text-stroke: 4px white;
  font-family: LinkStart;
    color: rgb(255, 255, 255, 0);
  
}

.enlace_incu{
    font-size: 2vw !important;
}

}

